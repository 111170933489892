


















import { AppState } from '@/store/api/AppState'
import { Component, Vue } from 'vue-property-decorator'
import { State } from 'vuex-class'
import QrcodeVue from 'qrcode.vue'

@Component({
  components: {
    QrcodeVue
  }
})
export default class NotMobileDevice extends Vue {
  @State private app!: AppState

  get affId() {
    return this.app?.affId
  }

  get affQrLink() {
    return 'https://widecare-switch.com/?aff_id=' + this.affId
  }
}
